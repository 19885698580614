<template>
  <div class="product">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner4.png" alt="" />
    </div>
    <div class="hardware">
      <div class="title-public">
        <p>Hardware</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">硬件</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="cnt">
        <img src="@/assets/img/page2-2b.png" alt="" />
        <div class="referral">
          <h3>
            <span>路灯系列</span>
            <span class="eng">LED Street light series /FER</span>
          </h3>
          <div class="classify">
            <span
              @click="skip(item)"
              v-for="(item, index) in hardwareList"
              :key="index"
              >{{ item.name }}
              <img src="@/assets/img/right.png" alt="" width="16" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="software">
      <div class="title-public">
        <p>Software</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">软件</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="cnt">
        <img src="@/assets/img/product-2.png" alt="" />
        <div class="referral">
          <h3>
            <span>软件系列</span>
            <span class="eng">Adobe /software number</span>
          </h3>
          <div class="classify">
            <span
              @click="skip(item)"
              v-for="(item, index) in softwareList"
              :key="index"
              >{{ item.name }}
              <img src="@/assets/img/right.png" alt="" width="16" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      hardwareList: [],
      softwareList: [],
    };
  },
  methods: {
    skip(item) {
      this.$router.push({ path: "/Product/info", query: { id: item.id,name:item.name } });
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/product-categories/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        parentId: 1,
      },
    }).then((res) => {
      console.log("硬件产品分类", res.data);
      this.hardwareList = res.data.data.list.reverse();
    });
    this.axios({
      method: "get",
      url: "/wzn/product-categories/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        parentId: 7,
      },
    }).then((res) => {
      console.log("软件产品分类", res.data);
      this.softwareList = res.data.data.list.reverse();
    });
  },
};
</script>
    
<style scoped>
.product {
}
.hardware,
.software {
  padding: 70px 0;
  /* width: 1400px;
  margin: 0 auto; */
}
.cnt {
  position: relative;
}
.cnt > img {
  width: 100%;
  height: 100vh;
}
.cnt .referral {
  position: absolute;
  bottom: 0;
  width: 38%;
  border-radius: 15px;
  transform: translateY(50%);
  overflow: hidden;
}
.hardware .cnt .referral {
  right: 10%;
}

.software .cnt .referral {
  left: 10%;
}
.referral h3 {
  height: 76px;
  background: #01adebd3;
  font-size: 30px;
  font-weight: inherit;
  padding: 0 60px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.referral h3 .eng {
  font-size: 20px;
}

.classify {
  height: 100px;
  background: #fff;
  padding: 30px 60px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-size: 16px;
  background: #fff;
}
.classify span {
  flex: 0 0 33%;
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: ease 0.4s;
}
.classify span:hover {
  color: #01aeeb;
}
</style>